<script>
import Swal from "sweetalert2";
export default {
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshZonesTable() {
      this.$http
        .post("/infrastructure/zones/list")
        .then((res) => (this.zones = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    deleteZone(servName, servUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la zone: " + servName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/infrastructure/zones/delete/" + servUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                 this.$toast.success(res.data.original.msg);
                  _this.refreshZonesTable();
                  break;

                case 500:
                 this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  },
  data() {
    return {
      showModal: false,
      tableData: [],
      title: "Zones",
      zones: [],
      items: [
        {
          text: "Infrastructures",
          href: "javascript:void(0)",
        },
        {
          text: "Zones",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
    };
  },

  computed: {
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;

    this.refreshZonesTable();
  },
};
</script>

<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <a @click="refreshZonesTable()" href="javascript:void()"
          ><h1><i class="bx bx-rotate-right" style="float: right;"></i></h1
        ></a>
        <router-link v-if="$can('create_administration_zone')" :to="{ name: 'base.infrastructures.zones.new' }"
          ><b-button variant="primary">Nouvelle Zone</b-button></router-link
        >
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Recherche..."
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th>Désignation</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in zones" :key="data.id">
                  <td>{{ data.name }}</td>
                  <td>{{ data.type }}</td>

                  <td>
                    <router-link
                    v-if="$can('update_administration_zone')"
                      :to="{
                        name: 'base.infrastructures.zones.edit',
                        params: { uid: data.uuid },
                      }"
                      class="mr-3 text-primary"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Edit"
                      ><i class="mdi mdi-pencil font-size-18"></i
                    ></router-link>
                    <a
                    v-if="$can('delete_administration_zone')"
                      @click="deleteZone(data.name, data.uuid)"
                      class="text-danger"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Delete"
                      ><i class="mdi mdi-close font-size-18"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
